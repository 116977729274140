import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import theme from 'src/utils/styles/theme'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import moment from 'moment-timezone'

import {
  CountdownSection,
  CountdownSectionContainer,
  CountDownContainer,
  Header,
  SecondaryHeader,
  Time,
  ColonWrapper,
  Label,
  LeftColumn,
  RightColumn,
  BuyNowContainer,
  BuyNowLink,
  Col,
  CalNYCol,
  Card,
  CalNYLabel,
  HideOnDesktop,
  HideOnMobile,
  DealHeadline,
  DealCard,
  DealContainer,
  DealTitle,
  DealPrice,
  ImageContainer,
  TextContainer,
  AmazonFlexContainer,
} from './styles'
import AmazonLink from '../Amazon'
import { BUY_LINK, CAL_BUY_LINK, FRAME_2_BUY_LINK, LOCALIZATION } from '../../utils/constants'

function CalColumn(props) {
  const { ctaTimeColor, labelColor, content, label } = props
  return (
    <CalNYCol>
      <Card>
        <Time color={ctaTimeColor} isCalendarNY>
          {content}
        </Time>
      </Card>
      <CalNYLabel color={labelColor}>{label}</CalNYLabel>
    </CalNYCol>
  )
}

const CountDownTimer = ({
  holidayDate,
  page,
  unified,
  noBottomMargin,
  sticky,
  cal,
  amazonLink,
  onCountdownComplete,
  mobileOnly = false,
  marginTop,
  ctaLinkOverride,
}) => {
  const { unifiedFrame, unifiedCalendar, frame, calendar, countdown } = useStaticQuery(graphql`
    query CountDownTimerQuery {
      unifiedFrame: file(relativePath: { eq: "mothers/frame-deal.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      unifiedCalendar: file(relativePath: { eq: "mothers/calendar-deal.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      frame: file(relativePath: { eq: "mothers/frame.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calendar: file(relativePath: { eq: "mothers/calendar.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      countdown: allContentfulCountdownTimer {
        edges {
          node {
            countdown_bg_color
            countdown_text_color
            countdown_cta_text_color
            countdown_cta_bg_color
            en_first_line
            en_second_line
            en_ca_first_line
            en_ca_second_line
            en_au_first_line
            en_au_second_line
            de_first_line
            de_second_line
            en_eu_first_line
            en_eu_second_line
            en_gb_first_line
            en_gb_second_line
            es_first_line
            es_second_line
            fr_first_line
            fr_second_line
            ie_first_line
            ie_second_line
            it_first_line
            it_second_line
            nl_first_line
            nl_second_line
          }
        }
      }
    }
  `)
  const countdownBgColor = countdown.edges[0].node.countdown_bg_color
  const countdownTextColor = countdown.edges[0].node.countdown_text_color
  const countdownCtaBgColor = countdown.edges[0].node.countdown_cta_bg_color
  const countdownCtaTextColor = countdown.edges[0].node.countdown_cta_text_color
  const countdownData = countdown?.edges?.[0]?.node
  const countryfirst = `${LOCALIZATION.replace(/-/g, '_')}_first_line`
  const countrysecond = `${LOCALIZATION.replace(/-/g, '_')}_second_line`

  const countdownTextFirstLine = countdownData?.[countryfirst]
  const countdownTextSecondLine = countdownData?.[countrysecond]

  const { t } = useTranslation('common')

  const [showDays, setShowDays] = useState('00')
  const [showHours, setShowHours] = useState('00')
  const [showMinutes, setShowMinutes] = useState('00')
  const [showSeconds, setShowSeconds] = useState('00')
  const [countdownCompleted, setCountdownCompleted] = useState(false)

  const convertToDoubleDigitString = (integer) => {
    if (integer > 9) return integer.toString()
    if (integer < 0) return `00`
    return `0${integer}`
  }

  const timeZones = {
    en: 'America/Los_Angeles', // PT
    'en-ca': 'America/New_York', // ET
    'en-au': 'Australia/Sydney', // AEDT
    'en-gb': 'Europe/London', // BST
    ie: 'Europe/Dublin', // IST (Irish Standard Time)
    'en-eu': 'Europe/Berlin', // CEST
    de: 'Europe/Berlin', // CEST
    fr: 'Europe/Paris', // CET
    it: 'Europe/Rome', // CET
    nl: 'Europe/Amsterdam', // CET
    es: 'Europe/Madrid', // CET
  }

  const targetTimeZone = timeZones[LOCALIZATION] || 'America/Los_Angeles' // Get the target timezone based on localization
  const countDownDate = moment.tz(holidayDate, targetTimeZone).valueOf() // Convert holiday date to target timezone

  useEffect(() => {
    const now = moment().valueOf()

    if (countDownDate - now <= 0) {
      setCountdownCompleted(true)
      onCountdownComplete()
    }
  }, [countDownDate, onCountdownComplete])

  useEffect(() => {
    const x = setInterval(() => {
      const now = moment().valueOf()
      const distance = countDownDate - now

      if (distance <= 0) {
        clearInterval(x)
        setCountdownCompleted(true)
        setShowDays('00')
        setShowHours('00')
        setShowMinutes('00')
        setShowSeconds('00')

        if (onCountdownComplete) {
          onCountdownComplete() // Notify that the countdown is complete
        }
      } else {
        const days = convertToDoubleDigitString(Math.floor(distance / (1000 * 60 * 60 * 24)))
        const hours = convertToDoubleDigitString(
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        )
        const minutes = convertToDoubleDigitString(
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        )
        const seconds = convertToDoubleDigitString(Math.floor((distance % (1000 * 60)) / 1000))

        setShowDays(days)
        setShowHours(hours)
        setShowMinutes(minutes)
        setShowSeconds(seconds)
      }
    }, 1000)

    return () => clearInterval(x)
  }, [countDownDate, onCountdownComplete])

  if (countdownCompleted) {
    return null
  }

  const HOLIDAY = {
    blackFriday: {
      mobileHeader: t(`${countdownTextFirstLine} ${countdownTextSecondLine}`),
      secondaryHeader: t(''),
      ctaText: t('Buy Now'),
      ctaLink: ctaLinkOverride || BUY_LINK,
      image: unifiedCalendar,
      isAmazonCta: false,
    },
    unifiedMom: {
      mobileHeader: `MOTHER'S DAY SALE`,
      ctaText: 'Shop Deals',
      ctaLink: CAL_BUY_LINK,
      frameCtaLink: BUY_LINK,
    },
    frameMom: {
      mobileHeader: `MOTHER'S DAY SALE`,
      ctaText: 'Shop Deals',
      ctaLink: BUY_LINK,
      quote: '“My Mom says this is the best gift she’s ever received.”',
      'en-au': 'My Mum says this is the best gift she’s ever received.',
      image: frame,
    },
    calendarMom: {
      mobileHeader: `MOTHER'S DAY SALE`,
      ctaText: 'Shop Deals',
      ctaLink: CAL_BUY_LINK,
      quote: '“Greatest gift for a busy mom! Works great, kids love it, wife loves it.”',
      image: calendar,
    },
    prime: {
      ctaText: 'Shop now at Amazon.com',
    },
  }

  const timeDisplay = [
    { content: showDays, label: 'DAYS' },
    { content: showHours, label: 'HRS' },
    { content: showMinutes, label: 'MINS' },
    { content: showSeconds, label: 'SECS' },
  ]
  if (countdownCompleted) {
    return null
  }
  const countdownDetails = HOLIDAY[page]

  return (
    <CountdownSection
      cal={cal}
      backgroundColor={theme[countdownBgColor]}
      sticky={sticky}
      unified={unified}
      mobileOnly={mobileOnly}
      marginTop={marginTop}
    >
      <CountdownSectionContainer noBottomMargin={noBottomMargin}>
        <LeftColumn>
          {countdownTextFirstLine && countdownTextFirstLine !== 'NONE' && (
            <Header
              noMarginMobile={countdownTextSecondLine && countdownTextSecondLine !== 'NONE'}
              titleColor={theme[countdownTextColor]}
            >
              {countdownTextFirstLine}
            </Header>
          )}
          {countdownTextSecondLine && countdownTextSecondLine !== 'NONE' && (
            <Header titleColor={theme[countdownTextColor]}>{countdownTextSecondLine}</Header>
          )}
          <AmazonFlexContainer>
            <CountDownContainer>
              {timeDisplay.map((time, i) => (
                <>
                  <Col>
                    <Time color={theme[countdownTextColor]}>{time.content}</Time>
                    <Label>
                      <Trans>{time.label}</Trans>
                    </Label>
                  </Col>
                  {i < timeDisplay.length - 1 && (
                    <ColonWrapper>
                      <Time color={theme[countdownTextColor]}>:</Time>
                    </ColonWrapper>
                  )}
                </>
              ))}
            </CountDownContainer>
            {countdownDetails.isAmazonCta && <AmazonLink link={amazonLink} />}
          </AmazonFlexContainer>
          {unified && (
            <>
              <HideOnMobile>
                <BuyNowContainer>
                  <BuyNowLink
                    bgColor={theme[countdownCtaBgColor]}
                    color={theme[countdownTextColor]}
                    to={countdownDetails.calCtaLink}
                    marginRight
                  >
                    Shop Calendars
                  </BuyNowLink>
                  <BuyNowLink
                    bgColor={theme[countdownCtaBgColor]}
                    color={theme[countdownTextColor]}
                    to={countdownDetails.frameCtaLink}
                  >
                    Shop Frames
                  </BuyNowLink>
                </BuyNowContainer>
              </HideOnMobile>
              <HideOnDesktop>
                {/* <Header>Top Deals</Header> */}
                <DealContainer>
                  <DealCard>
                    <DealTitle>15" Calendar </DealTitle>
                    <Link to={countdownDetails.calCtaLink}>
                      <GatsbyImage
                        image={unifiedCalendar.childImageSharp.gatsbyImageData}
                        alt="Skylight Calendar on week mode"
                      />{' '}
                    </Link>
                    <p />
                    {/* <DealPrice>
                      $299.99 <span>$249.99</span>
                    </DealPrice> */}
                    <BuyNowContainer>
                      <BuyNowLink
                        bgColor="#ED9D9C"
                        color={theme.darkGray}
                        to={countdownDetails.calCtaLink}
                      >
                        Shop Calendars
                      </BuyNowLink>
                    </BuyNowContainer>
                  </DealCard>
                  <DealCard>
                    <DealTitle>10" Black Frame</DealTitle>
                    <Link to={countdownDetails.frameCtaLink}>
                      <GatsbyImage
                        image={unifiedFrame.childImageSharp.gatsbyImageData}
                        alt="Skylight Frame shown on portrait with a mom and child embracing with pink flowers"
                      />
                    </Link>
                    <p />
                    {/* <DealPrice>
                      $159.99 <span>$199.99</span>
                    </DealPrice> */}
                    <BuyNowContainer>
                      <BuyNowLink
                        bgColor="#ED9D9C"
                        color={theme.darkGray}
                        to={countdownDetails.frameCtaLink}
                      >
                        Shop Frames
                      </BuyNowLink>
                    </BuyNowContainer>
                  </DealCard>
                </DealContainer>
              </HideOnDesktop>
            </>
          )}
        </LeftColumn>
        {!unified && page !== 'prime' && (
          <RightColumn>
            {/* <ImageContainer cal={cal}>
              <GatsbyImage
                image={countdownDetails.image.childImageSharp.gatsbyImageData}
                alt="Skylight Frame shown on portrait with a mom and child embracing with pink flowers"
              />
            </ImageContainer> */}
            <TextContainer>
              <SecondaryHeader>{countdownDetails.secondaryHeader}</SecondaryHeader>
              <BuyNowContainer>
                <BuyNowLink
                  bgColor={theme[countdownCtaBgColor]}
                  color={theme[countdownCtaTextColor]}
                  to={countdownDetails.ctaLink}
                >
                  {countdownDetails.ctaText}
                </BuyNowLink>
              </BuyNowContainer>
            </TextContainer>
          </RightColumn>
        )}
      </CountdownSectionContainer>
    </CountdownSection>
  )
}

CountDownTimer.propTypes = {
  holidayDate: PropTypes.string.isRequired,
  page: PropTypes.string,
  unified: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  sticky: PropTypes.bool,
  cal: PropTypes.bool,
  amazonLink: PropTypes.string,
  onCountdownComplete: PropTypes.func.isRequired,
  mobileOnly: PropTypes.bool,
  marginTop: PropTypes.bool,
  ctaLinkOverride: PropTypes.string,
}
CalColumn.propTypes = {
  ctaTimeColor: PropTypes.string,
  labelColor: PropTypes.string,
  content: PropTypes.any,
  label: PropTypes.string,
}
export default CountDownTimer
