import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.grayDark};
`
export const ImageContainer = styled.div`
  min-width: 160px;
  div:nth-child(2) {
    display: none;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 250px;
    > div:first-child {
      display: none;
    }
    div:nth-child(2) {
      display: block;
    }
  }
`
export const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  /* border: ${(props) => (props.calendar ? 'none' : '1px solid #000')};
  padding: 28px 40px; */
  @media (max-width: 1300px) {
    flex-direction: column;
  }
  /* @media (max-width: ${breakpoints.s}px) {
    border-left: none;
    border-right: none;
    padding: 32px 0px;
  } */
`
export const Amazon = styled.p`
  font-family: 'FilsonPro' !important;
  background-color: #ffa41c;
  padding: 11px 10px;
  width: 100%;
  margin: 0px 0px 0px 28px;
  border-radius: 30px;
  text-align: center;
  @media (max-width: 1300px) {
    margin: 16px 0px 0px;
    max-width: 300px;
  }
  @media (max-width: ${breakpoints.s}px) {
    max-width: 90%;
  }
`
export const AmazonPrime = styled.p`
  font-family: 'FilsonPro' !important;
  background-color: #ffa41c;
  padding: 10px 30px;
  width: 100%;
  margin: 0px 0px 0px 28px;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 1620px) {
    padding: 12px 20px;
  }
  @media (max-width: 1500px) {
    margin: 0px 0px 0px 5px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px;
    font-size: 16px;
  }
  @media (max-width: 350px) {
    font-size: 15px;
  }
  @media (max-width: ${breakpoints.s}px) {
    max-width: 100%;
  }
`
