import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'
import { Link, ImageContainer, CtaContainer, AmazonPrime } from './styles'
import Analytics from '../../utils/Analytics'

const AmazonLink = ({ calendar, link }) => {
  const { amazon, amazonMobile } = useStaticQuery(graphql`
    query AmazonLinkQuery {
      amazon: file(relativePath: { eq: "amazon.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      amazonMobile: file(relativePath: { eq: "amazon-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <div>
      <Link
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => Analytics.track('Clicked to Amazon')}
      >
        <CtaContainer calendar={calendar}>
          {/* <ImageContainer>
            <GatsbyImage image={amazon.childImageSharp.gatsbyImageData} alt="Available at Amazon" />
            <GatsbyImage
              image={amazonMobile.childImageSharp.gatsbyImageData}
              alt="Available at Amazon"
            />
          </ImageContainer> */}
          <AmazonPrime>
            <Trans>Shop now at Amazon.com</Trans>
          </AmazonPrime>
        </CtaContainer>
      </Link>
    </div>
  )
}

AmazonLink.propTypes = {
  calendar: PropTypes.bool,
  link: PropTypes.string,
}

export default AmazonLink
